const fallback = [
    {
        anchor: 'pe-points',
        label: 'PE overzicht',
    },
    {
        anchor: 'certificates',
        label: 'Registraties',
    },
    {
        anchor: 'courses',
        label: 'Cursussen',
    },
    {
        anchor: 'activities',
        label: 'Activiteiten',
    },
    {
        anchor: 'surveylists',
        label: 'Vragenlijsten',
    },
    {
        anchor: 'invoices',
        label: 'Facturen',
    },
    {
        anchor: 'documents',
        label: 'Documenten',
    },
    {
        anchor: 'downloads',
        label: 'Downloads',
    },
    {
        anchor: 'personal-data',
        label: 'Persoonsgegevens',
    },
];

const backToProfile = [{
    label: 'Terug naar profiel',
    to: {
        path: '/',
    },
}];

const certificatesPaths = [
    {
        anchor: 'certificates',
        label: 'Registraties',
    },
    {
        anchor: 'documents',
        label: 'Documenten',
    },
    {
        label: 'Terug naar profiel',
        to: {
            path: '/',
        },
    },
];

const unsubscribePaths = [
    {
        anchor: 'unsubscribe',
        label: 'Uitschrijving uit alle kamers',
    },
    {
        label: 'Terug naar profiel',
        to: {
            path: '/',
        },
    },
];

const scrollLinks = path => ({
    'extranet-certificates-certificateId-add': backToProfile,
    'extranet-certificates-certificateId-unsubscribe': backToProfile,
    'extranet-certificates-view-id': certificatesPaths,
    'extranet-companies-edit': backToProfile,
    'extranet-personal-movements': backToProfile,
    'extranet-personal-password': backToProfile,
    'extranet-personal-profile': backToProfile,
    'extranet-profile-edit': backToProfile,
    'extranet-surveylist-view-uuid': backToProfile,
    'extranet-unsubscribe-all': unsubscribePaths,
}[path]) || fallback;

export default scrollLinks;
