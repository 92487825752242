export default [
    {
        href: 'mailto:info@nrvt.nl',
        label: 'Contact: info@nrvt.nl',
        target: '_blank',
    },
    {
        href: 'tel:+310103161200',
        label: '+31(0)10 31 61 200',
        target: '_blank',
    },
];
