export default [
    {
        label: 'Dashboard',
        to: {
            path: '/dashboard',
        },
    },
    {
        label: 'PE Dashboard',
        to: {
            path: '/dashboard/pe',
        },
    },
    {
        label: 'Relaties',
        to: {
            path: '/relations',
        },
    },
    {
        label: 'Bedrijven',
        to: {
            path: '/companies',
        },
    },
    {
        label: 'Registraties',
        to: {
            path: '/certificates',
        },
    },
    {
        label: 'Cursussen',
        to: {
            path: '/courses',
        },
    },
    {
        label: 'Facturen',
        to: {
            path: '/invoices',
        },
    },
    {
        label: 'Rapporten',
        to: {
            path: '/reports',
        },
    },
    {
        label: 'Extranet documenten',
        to: {
            path: '/documents',
        },
    },
    {
        label: 'Vragenlijsten',
        to: {
            path: '/surveylists',
        },
    },
    {
        label: 'Instellingen',
        to: {
            path: '/settings/users',
        },
    },
    {
        href: 'https://www.nrvt.nl/',
        label: 'www.nrvt.nl',
        target: '_blank',
    },
];
